body, html,*{
  box-sizing: border-box;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: '#f8f8f8';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.CP-questions {
  font-weight: 500;
  font-size: 14;
  font-style: normal;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: black;
  line-height: 20;
  text-transform: capitalize;
}

.timeline-item {
  display: flex;
  margin-bottom: 20px;
}
.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #002060; /* Change the color as needed */
  margin-right: 20px;
  position: relative;
  
}

.timeline-circle::before {
  content: '';
    position: absolute;
    left: 28%;
    bottom: 30%;
    transform: translate(-35%, 37%);
    width: 30px;
    height: 30px;
    border-radius: 74%;
    border: 2px solid #002060; 
}
.timeline-content {
  display: flex;
  width: 100%;
}

.left-content {
  flex: 1;
  text-align: right;
  margin-right: 20px;
}

.right-content {
  flex: 3;
}
.timeline-connector {
  position: absolute;
  top: 50%;
  left: calc(26% + 9px);
  transform: translateY(-50%);
  width: 2px;
  height: 100%;
  background-color: #002060;
  z-index: 1;/* Ensure the connector is behind the circles */
}
.vertical-timeline {
  position: relative;
}
/* styles.css or App.css */
.swal2-container {
  z-index: 2001 !important; /* Ensure this is higher than Material-UI dialog */
}
